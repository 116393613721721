
.side-by-side {
  border: 0px;
}
.side-by-side td {
  border: 0px;
}
.side-by-side thead td {
  text-align: center;
  font-weight: bold;
}

.authorpic {
  float: right;
  margin-left: 30px;
  margin-bottom: 80px;
}

.robotpicright {
  float: right;
  margin-left: 30px;
  margin-bottom: 80px;
}

.slideright {
  float: right;
  margin-left: 30px;
  margin-bottom: 20px;
}

.pseudocode {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-family: fixed;
}
